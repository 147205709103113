<template>
  <b-card
  >
    <cobertura-filters
      :is-cobertura-filters-sidebar-active.sync="isFiltersSidebarActive"
      @cobertura-fetch-data="getDataCobertura"
    />

    <cobertura-detail-modal></cobertura-detail-modal>

    <div
      v-if="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-else
    >
      <b-row class="d-flex justify-content-center title-month">
        Cobertura del {{currentMonth}}
      </b-row>

      <b-row class="align-items-center">
        <b-button
          @click="isFiltersSidebarActive = true"
          variant="primary"
          class="m-1"
        >
        <b-icon-sliders/>  Filters
        </b-button>
        <div v-if="filtersData.bpu.length > 0" class="mr-1">
          <strong>BPU: </strong> {{filtersData.bpu[0]}}
        </div>
        <div v-if="filtersData.brand_category.length > 0" class="mr-1">
          <strong>BRAND CATEGORY: </strong> {{filtersData.brand_category[0]}}
        </div>
        <div v-if="filtersData.application_form.length > 0" class="mr-1">
          <strong>APPLICATION FORM: </strong> {{filtersData.application_form[0]}}
        </div>
        <div class="mr-1">
          <strong v-if="showCoberturaInicial.showTitle">COBERTURA INICIAL: </strong>
          <span v-if="showCoberturaInicial.showCoberturaMayor">Mayor a {{filtersData.cobertura_inicial_mayor}}</span>
          <span v-if="showCoberturaInicial.showY"> y </span>
          <span v-if="showCoberturaInicial.showCoberturaMenor">Menor a {{filtersData.cobertura_inicial_menor}}</span>
        </div>
        <div class="mr-1">
          <strong v-if="showCoberturaFinal.showTitle">COBERTURA FINAL: </strong>
          <span v-if="showCoberturaFinal.showCoberturaMayor">Mayor a {{filtersData.cobertura_final_mayor}}</span>
          <span v-if="showCoberturaFinal.showY"> y </span>
          <span v-if="showCoberturaFinal.showCoberturaMenor">Menor a {{filtersData.cobertura_final_menor}}</span>
        </div>
        
      </b-row>

      <b-row>
        <b-col
          v-for="item in Object.keys(totales)"
          :key="item"
          xl="2"
          sm="6"
          class="my-2"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-primary"
              >

                <feather-icon
                  v-if="item.toUpperCase() == 'TOTALES_NETSALES_IMPACT' || item.toUpperCase() == 'NS_IMPACT'"
                  size="24"
                  icon="DollarSignIcon"
                />
                <feather-icon
                  v-else
                  size="24"
                  icon="PackageIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                <!-- {{numberWithCommas(Number(totales[item]).toFixed(0))}} -->
                <!-- {{totales[item].toLocaleString('en-US', {maximumFractionDigits: 0})}} -->
                {{formatItemVal(item)}}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                <!-- {{item}} -->
                {{formatItemDesc(item)}}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>

      <v-grid
        ref="refGridSopCobertura"
        :source="source" 
        :columns="columns" 
        :columnTypes="pluginRevo"
        theme="material" 
        resize="true"
        autoSizeColumn="true"
        @beforeeditstart="beforeEditStart"
        class="grid-container-sop-cobertura"
      ></v-grid>
    </div>
  </b-card>
</template>

<script>
import { 
  BCard, BButton, BCardBody,
  BRow, BMedia, BMediaAside, BAvatar, BMediaBody,
  BCol, BCardText, BIconSliders
} from 'bootstrap-vue'
import { ref, getCurrentInstance, computed, provide } from 'vue'
import VGrid from '@revolist/vue-datagrid'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import axios from '@axios'
import CoberturaFilters from './CoberturaFilters.vue'
import CoberturaDetailModal from './CoberturaDetailModal.vue'
import { numericSort } from '@/libs/revo-grid/custom-sort'

export default {
  components: {
    BRow,
    BCard,
    BButton,
    BCardBody,
    BMedia, 
    BMediaAside, 
    BAvatar, 
    BMediaBody,
    BCol,
    BCardText,

    CustomLoading,
    VGrid,
    CoberturaFilters,
    CoberturaDetailModal,
    BIconSliders
  },
  setup(props) {
    const source = ref([])
    const isLoading = ref(true)
    const refGridSopCobertura = ref(null)
    const isFiltersSidebarActive = ref(false)
    const selectedCell = ref(null)
    const columns = ref([])
    const filtersData = ref({
      bpu: [],
      brand_category: [],
      application_form: [],
      cobertura_inicial_mayor: null,
      cobertura_inicial_menor: null,
      cobertura_final_mayor: null,
      cobertura_final_menor: null,
    })
    const totales = ref({
      TOTALES_FC: 0,
      TOTALES_IMPACTO: 0,
      TOTALES_NETSALES_IMPACT: 0,
      SL_PROYECCION: 0,
      NS_IMPACT: 0,
    })
    const currentMonth = ref('000000')

    // provide
    provide('selectedCell', selectedCell)


    const pluginRevo = { 
      'numeric': new NumberColumnType('0,0'),
      'decimal': new NumberColumnType('0,0.0'),
      'percentage': new NumberColumnType('0,0[.]00 %'),
      'numeric_negative': new NumberColumnType('(0,0)'),
    }

    const vm = getCurrentInstance().proxy

    columns.value = [
      {
        "name": "BPU",
        "prop": "BPU",
        "size": 150,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
      },
      {
        "name": "BRAND CATEGORY",
        "prop": "BrandCategory",
        "size": 200,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
      },
      {
        "name": "APPLICATION FORM",
        "prop": "ApplicationForm",
        "size": 220,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
      },
      {
        "name": "SPGR",
        "prop": "SPGR",
        "size": 100,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
      },
      {
        "name": "NART",
        "prop": "NART",
        "size": 150,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
      },
      {
        "name": "DESCRIPCION",
        "prop": "DESCRIPCION",
        "size": 250,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
      },
      {
        "name": "FC",
        "prop": "FC",
        "size": 90,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
      },
      {
        "name": "STOCK",
        "prop": "STOCK",
        "size": 110,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
      },
      {
        "name": "C. INI",
        "prop": "COBERTURA_INICIAL",
        "size": 90,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'decimal',
      },
      {
        "name": "TRANS",
        "prop": "TRANSITO",
        "size": 90,
        "autoSize": true,
        "sortable": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
      },
      {
        "name": "C. FIN",
        "prop": "COBERTURA_FINAL",
        "size": 90,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'decimal',
      },
      {
        "name": "IMPACT",
        "prop": "IMPACTO",
        "size": 110,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
      },
      {
        "name": "NSP",
        "prop": "NSP",
        "size": 70,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'decimal',
      },
      {
        "name": "NS IMPACT",
        "prop": "NETSALES_IMPACT",
        "size": 120,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
      },
    ]

    const showCoberturaInicial = computed(() => {
      let showCoberturaMayor = filtersData.value.cobertura_inicial_mayor != null && filtersData.value.cobertura_inicial_mayor.trim().length > 0
      let showCoberturaMenor = filtersData.value.cobertura_inicial_menor != null && filtersData.value.cobertura_inicial_menor.trim().length > 0

      return {
        showTitle: showCoberturaMayor || showCoberturaMenor,
        showCoberturaMayor,
        showCoberturaMenor,
        showY: showCoberturaMayor && showCoberturaMenor
      }
    })

    const showCoberturaFinal = computed(() => {
      let showCoberturaMayor = filtersData.value.cobertura_final_mayor != null && filtersData.value.cobertura_final_mayor.trim().length > 0
      let showCoberturaMenor = filtersData.value.cobertura_final_menor != null && filtersData.value.cobertura_final_menor.trim().length > 0

      return {
        showTitle: showCoberturaMayor || showCoberturaMenor,
        showCoberturaMayor,
        showCoberturaMenor,
        showY: showCoberturaMayor && showCoberturaMenor
      }
    })

    const formatItemDesc = (val) => {
      return val.split('_').join(' ')
    }

    const formatItemVal = (val) => {
      if(val.toUpperCase() == "NS_IMPACT"
      || val.toUpperCase() == "SL_PROYECCION") {
        return Number(totales.value[val]).toLocaleString(undefined, {style: 'percent', minimumFractionDigits:1});
      }

      return totales.value[val].toLocaleString('en-US', {maximumFractionDigits: 0})
    }

    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const getCoberturaCondition = (menorValue, mayorValue) => {
      let format = {}
      if(menorValue != null && menorValue != "") format["_lte"] = menorValue
      if(mayorValue != null && mayorValue != "") format["_gte"] = mayorValue
      return format
    }

    const getDataCobertura = async(dataCob) => {
      try {
        isLoading.value = true

        if (dataCob == null || dataCob == undefined) dataCob = filtersData.value
        filtersData.value = Object.assign({}, dataCob)

        const result = await axios
        .post(`${process.env.VUE_APP_BASE_URL}/cobertura_graph`, {
          data: {
            bpu: dataCob.bpu,
            brand_category: dataCob.brand_category,
            application_form: dataCob.application_form,
            COBERTURA_INICIAL: getCoberturaCondition(dataCob.cobertura_inicial_menor, dataCob.cobertura_inicial_mayor),
            COBERTURA_FINAL: getCoberturaCondition(dataCob.cobertura_final_menor, dataCob.cobertura_final_mayor),
          }
        })

        source.value = result.data.result.data
        totales.value = result.data.result.totales
        currentMonth.value = result.data.result.mes_en_curso
        
      } catch(e) {
        console.log(e)
      } finally {
        isLoading.value = false
      }
    }

    const beforeEditStart = (event) => {
      event.preventDefault()
      selectedCell.value = event.detail

      if(selectedCell.value.prop != "TRANSITO") return

      vm.$bvModal.show('cobertura-detail-modal')
    }

    getDataCobertura()

    return {
      source,
      columns,
      isLoading,
      refGridSopCobertura,
      pluginRevo,
      isFiltersSidebarActive,
      filtersData,
      totales,
      currentMonth,

      showCoberturaInicial,
      showCoberturaFinal,

      getDataCobertura,
      formatItemDesc,
      formatItemVal,
      numberWithCommas,
      beforeEditStart,
    }
  },
}
</script>

<style lang="scss">
.title-month {
  font-size: 35px;
  font-weight: bold;
}

.custom-sticky {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.grid-container-sop-cobertura {
  width: 100%;
  height: 70vh;
  font-family: "Montserrat", Helvetica, Arial, serif !important;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .header-rgRow {
    background: #0032a0;
    color: white;
  }

  .rgRow .disabled {
    background: none;
  }

  .rgHeaderCell.sortable:hover {
    background-color: #f1f1f1;
    color: black;
  }
}

@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
