<template>
  <b-sidebar
    id="cobertura-filters-sidebar"
    :visible="isCoberturaFiltersSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="showFilterSidebar"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Filters
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- BPU -->
          <validation-provider
            #default="validationContext"
            name="BPU"
          >
            <b-form-group
              label="BPU"
              label-for="bpu"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newFiltersData.bpu"
                :options="bpuOptions"
                :reduce="val => [val.value]"
                input-id="bpu"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Brand Category -->
          <validation-provider
            #default="validationContext"
            name="Brand Category"
          >
            <b-form-group
              label="Brand Category"
              label-for="brand_category"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newFiltersData.brand_category"
                :options="brandCategoryOptions"
                :reduce="val => [val.value]"
                input-id="brand_category"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- APPLICATION FORM -->
          <validation-provider
            #default="validationContext"
            name="Application Form"
          >
            <b-form-group
              label="Application Form"
              label-for="application_form"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newFiltersData.application_form"
                :options="applicationFormOptions"
                :reduce="val => [val.value]"
                input-id="application_form"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div>
            <label>Cobertura Inicial</label>
            <div class="d-flex">
              <!-- Cobertura Inicial Mayor -->
              <validation-provider
                #default="validationContext"
                name="Cobertura Inicial Mayor a"
                rules="regex:^-?[0-9]\d*(.\d+)?$"
              >
                <b-form-group
                  label="Mayor a"
                  label-for="cobertura_inicial_mayor"
                >
                  <b-form-input
                    id="cobertura_inicial_mayor"
                    v-model="newFiltersData.cobertura_inicial_mayor"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Cobertura Inicial Menor -->
              <validation-provider
                #default="validationContext"
                name="Cobertura Inicial Menor a"
                rules="regex:^-?[0-9]\d*(.\d+)?$"
                class="ml-1"
              >
                <b-form-group
                  label="Menor a"
                  label-for="cobertura_inicial_menor"
                >
                  <b-form-input
                    id="cobertura_inicial_menor"
                    v-model="newFiltersData.cobertura_inicial_menor"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          </div>

          <div>
            <label>Cobertura Final</label>
            <div class="d-flex">
              <!-- Cobertura Final Mayor -->
              <validation-provider
                #default="validationContext"
                name="Cobertura Final Mayor a"
                rules="regex:^-?[0-9]\d*(.\d+)?$"
              >
                <b-form-group
                  label="Mayor a"
                  label-for="cobertura_final_mayor"
                >
                  <b-form-input
                    id="cobertura_final_mayor"
                    v-model="newFiltersData.cobertura_final_mayor"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Cobertura Final Menor -->
              <validation-provider
                #default="validationContext"
                name="Cobertura Final Menor a"
                rules="regex:^-?[0-9]\d*(.\d+)?$"
                class="ml-1"
              >
                <b-form-group
                  label="Menor a"
                  label-for="cobertura_final_menor"
                >
                  <b-form-input
                    id="cobertura_final_menor"
                    v-model="newFiltersData.cobertura_final_menor"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          </div>
          

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Aplicar
            </b-button>

            <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="mr-2"
            >
              Cancel
            </b-button> -->

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Reset Filtros
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, getCurrentInstance } from 'vue'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import gql from 'graphql-tag'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isCoberturaFiltersSidebarActive',
    event: 'update:is-cobertura-filters-sidebar-active',
  },
  props: {
    isCoberturaFiltersSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const bpuOptions = ref([])
    const brandCategoryOptions = ref([])
    const applicationFormOptions = ref([])
    const filtersDataHelper = ref(null)

    const vm = getCurrentInstance().proxy

    const blankFiltersData = {
      bpu: [],
      brand_category: [],
      application_form: [],
      cobertura_inicial_mayor: "",
      cobertura_inicial_menor: "",
      cobertura_final_mayor: "",
      cobertura_final_menor: "",
    }

    const newFiltersData = ref(JSON.parse(JSON.stringify(blankFiltersData)))
    const resetuserData = () => {
      newFiltersData.value = JSON.parse(JSON.stringify(blankFiltersData))
    }

    const onSubmit = () => {
      if (newFiltersData.value.bpu == null) newFiltersData.value.bpu = []
      if (newFiltersData.value.brand_category == null) newFiltersData.value.brand_category = []
      if (newFiltersData.value.application_form == null) newFiltersData.value.application_form = []

      filtersDataHelper.value = Object.assign({}, newFiltersData.value)

      emit('cobertura-fetch-data', newFiltersData.value)
    }

    const showFilterSidebar = (val) => {
      if(val && filtersDataHelper.value != null) {
        newFiltersData.value =  Object.assign({}, filtersDataHelper.value)
      }
      emit('update:is-cobertura-filters-sidebar-active', val)
    }

    // subscription
    vm.$apollo.addSmartSubscription('getFiltersDataBpu', {
      query: gql`
        subscription getFiltersDataBpu {
          bpuOptions: Maestro_productos(distinct_on: BPU) {
            label: BPU
            value: BPU
          }
        }
      `,
      result ({data}) {
        bpuOptions.value = data.bpuOptions
      },
    })

    vm.$apollo.addSmartSubscription('getFiltersDataBrandCategory', {
      query: gql`
        subscription getFiltersDataBrandCategory {
          brandCategoryOptions: Maestro_productos(distinct_on: BrandCategory) {
            label: BrandCategory
            value: BrandCategory
          }
        }
      `,
      result ({data}) {
        brandCategoryOptions.value = data.brandCategoryOptions
      },
    })

    vm.$apollo.addSmartSubscription('getFiltersDataApplicationForm', {
      query: gql`
        subscription getFiltersDataApplicationForm {
          applicationFormOptions: Maestro_productos(distinct_on: ApplicationForm) {
            label: ApplicationForm
            value: ApplicationForm
          }
        }
      `,
      result ({data}) {
        applicationFormOptions.value = data.applicationFormOptions
      },
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      newFiltersData,
      onSubmit,
      showFilterSidebar,

      bpuOptions,
      brandCategoryOptions,
      applicationFormOptions,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

#cobertura-filters-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
