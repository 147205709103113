<template>
  <b-modal
    id="cobertura-detail-modal"
    ref="CoberturaDetailModal"
    centered
    no-close-on-backdrop
    title="Detalle"
    @show="getData"
    size="lg"
    ok-only
    cancel-variant="outline-secondary"
  >
    <div
      v-show="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-show="!isLoading"
    >
      <v-grid
        ref="refGridCoberturaDetailModal"
        :source="source" 
        :columns="columns"
        :columnTypes="pluginRevo"
        theme="material" 
        resize="true"
        autoSizeColumn = trues
        readOnly="true"
        class="grid-container-fc-modal"
      ></v-grid>
    </div>
  </b-modal>
</template>

<script>
import { BButton, BModal, BCardText, BSpinner, BImg, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, inject, getCurrentInstance } from 'vue'
import axios from '@axios'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import VGrid from '@revolist/vue-datagrid'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import { numericSort } from '@/libs/revo-grid/custom-sort'

export default {
  components: {
    BButton,
    BModal,
    BCardText,
    BSpinner,
    BImg,

    VGrid,
    CustomLoading,
  },
  directives: {
    Ripple,
  },
  setup() {
    const CoberturaDetailModal = ref(null)
    const isLoading = ref(true)
    const source = ref([])
    const columns = ref([])
    const refGridCoberturaDetailModal = ref(null)

    const pluginRevo = { 'numeric': new NumberColumnType('0,0') }

    const vm = getCurrentInstance().proxy

    // inject
    const selectedCell = inject("selectedCell")

    columns.value = [
      {
        "name": "Fecha Stock",
        "prop": "idfecha",
        "size": 130,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "Nart",
        "prop": "nart",
        "size": 150,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "Fecha Llegada",
        "prop": "fechaLlegada",
        "size": 130,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "Unidades",
        "prop": "unidades",
        "size": 100,
        "autoSize": true,
        "sortable": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
      }
    ]

    // methods
    const getData = async () => {
      try {
        isLoading.value = true

        const result = await axios
        .post(`${process.env.VUE_APP_BASE_URL}/get_transito_nart`,{
          nart: selectedCell.value.model.NART
        })

        source.value = result.data
      } catch(e) {
        console.log(e)
      } finally {
        isLoading.value = false
      }
    }

    return {
      source,
      columns,
      CoberturaDetailModal,
      refGridCoberturaDetailModal,
      pluginRevo,
      isLoading,

      getData,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

#cobertura-detail-modal {
  .modal-lg {
    max-width: 600px;
  }
}

.grid-container-fc-modal {
  width: 100%;
  height: 25vh;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .header-rgRow {
    background: #0032a0;
    color: white;
  }

  .rgRow .disabled {
    background: none;
  }

  .rgHeaderCell.sortable:hover {
    background-color: #f1f1f1;
    color: black;
  }
}
</style>